<template>
  <div class="auth-page">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 col-md-6 d-none d-md-block">
          <AuthLeft :paragraph="para" :member="mem" :button3="btn" />
        </div>
        <div class="col-12 col-md-6">
          <div class="auth-page-right d-flex flex-column justify-content-center">
            <!-- <div class="logo">
              <h4>Shock Shield</h4>
            </div> -->
            <div class="auth-page-right-content text-center">
              <h6>Let's verify your Email.</h6>
              <div class="success-img">
                <img src="../../assets/images/success.png" alt="" />
              </div>
              <p>Please check verification link sent to your email.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthLeft from "../../components/authleft/AuthLeft.vue";

export default {
  name: "VerificationEmail",

  components: {
    AuthLeft,
  },

  data: function() {
    return {
      para: "Sign up to enjoy the perks!",
      mem: "Already a member?",
      btn: "Sign In"
    };
  },

};
</script>
